<template>
  <div>
    <b-card>
      <b-button
        variant="primary"
        @click="goToUpsert('new')"
      >
        <feather-icon icon="PlusCircleIcon" />
        {{ $t('calcParams.new') }}
      </b-button>
    </b-card>
    <b-card no-body>
      <b-table
        striped
        hover
        responsive
        class="position-relative"
        :items="readItems"
        :fields="fields"
      >
        <template #cell(Asset)="data">
          {{ data.item.Asset ? data.item.Asset.name : 'N/A' }}
        </template>
        <template #cell(ReducingDeciLayer)="data">
          {{ data.item.ReducingDeciLayer.pot }}
        </template>
        <template #cell(nomem)="data">
          {{ data.item.nomem }}
        </template>
        <template #cell(action)="data">
          <b-link
            @click="goToUpsert(data.item.id)"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              size="20"
            />
          </b-link>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BButton,
  BLink,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BTable,
    BButton,
    BLink,
  },
  data() { return { items: [] } },
  computed: {
    fixCause() {
      return cause => this.$t(`calcParams.${cause}`)
    },
    readItems() { return this.items },
    fields() {
      return [
        { key: 'Asset', label: this.$t('calcParams.table.asset'), sortable: true },
        { key: 'ReducingDeciLayer', label: this.$t('calcParams.table.reducingDeciLayer'), sortable: true },
        { key: 'nomem', label: this.$t('calcParams.table.nomem'), sortable: true },
        { key: 'action', label: this.$t('users.table.action'), sortable: true },
      ]
    },
  },
  async created() { await this.load() },
  methods: {
    goToUpsert(id) {
      this.$router.push({ name: 'pages-calcparam-upsert', params: { id } })
    },
    async load() {
      this.items = await this.$store.dispatch('calcParam/index')
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@media (max-width: 767px){
  .small-devices-filterby{
    display: block;
  }
}
.dropdown button {
    padding: 0px;
}
.em-dropdown .dropdown-menu {
  max-height: 100px;
  overflow-y: hidden;
}
.dropdown-item{
  padding: 0 4px;
}
</style>
